import React from "react"
import Layout from "../components/layout"
import styled from "@emotion/styled"
import { Global, css } from "@emotion/react"
import {color, spacing, screen} from "../constants"
import { Link } from "gatsby"

import useToggle from "../components/useToggle"
import Footer from "../components/footer"

export default function Saison2021() {
  const [dateOpen, toggleDate] = useToggle();
  const [ticketOpen, toggleTicket] = useToggle();
  
  return (
    <Layout title="Saison 2024 / 2025">
      <main css={css`
        background-color: ${color.light};
        flex: 1 1 980px;
        
        padding: ${spacing.xs};
        margin: 0;

        strong {
          color: black;
        }

        @media (min-width: ${screen.xsPhone}) {
          padding: ${spacing.medium};
          margin: 0 ${spacing.small};
        }
        @media (min-width: ${screen.tablet}) {
          padding: calc(0.75*${spacing.big}) ${spacing.big};
          margin: 0 ${spacing.medium};
        }
      `}>
        <h1>Saisonprogramm 2024/2025</h1>

        {/* <p>
          <br/>
          <hr />
        </p>

        <p><a css={css`text-decoration: none; color: ${color.secondaryDark};`} href="/GML_Kreuzlingen_Jahresprogramm_2024_25.pdf">Unser Jahresprogramm Saison 2024/25 als PDF Download</a>&nbsp;</p>

        <p>
          <hr />
          <br/>
        </p> */}

        <p>Freitag, 27. September 2024; Kult-X Kreuzlingen</p>
        <p>18.15 Uhr: GML-Generalversammlung</p>
        <p>19.30 Uhr: <b>Konzert I | „O Sweet Woods“ mit dem Ensemble La Vuelta</b></p>
        <p>Das Ensemble La Vuelta vereint in seinem neuen Programm alte und neue Musik rund um die Thematik Wald, Baum, Ranken, Dornen. Kurze und kürzeste Stücke – darunter Uraufführungen von Barbara Hidber, Andreas Fervers und Regina Irman – erscheinen im Wechsel mit ebensolchen Texten aus verschiedenen Jahrhunderten.</p>

        <p>
          <br/>
          <hr />
          <br/>
        </p>

        <p>Sonntag, 17. November 2024, 17 Uhr; Evangelische Kirche Kreuzlingen</p>
        <p><b>Konzert II | Mendelssohns Oratorium „Paulus“ mit dem Vokalensemble Bacchanto</b></p>
        <p>Unter der Leitung von Raphael Jud bringt das Vokal- und Instrumentalensemble Bacchanto das berühmte Oratorium Paulus des deutschen Romantikers Felix Mendelssohn nach Kreuzlingen.</p>

        <p>
          <br/>
          <hr />
          <br/>
        </p>

        <p>Sonntag, 12. Januar 2025, 17 Uhr; Kirche St. Ulrich Kreuzlingen</p>
        <p><b>Konzert III | Festliche Neujahrsmusik mit Trompeten, Perkussion und Orgel</b></p>
        <p>Mit festlichen Klängen beginnen wir das neue Jahr. Werke von Mouret, Händel und Charpentier stehen im Mittelpunkt dieses Konzerts.</p>

        <p>
          <br/>
          <hr />
          <br/>
        </p>

        <p>Samstag, 22. Februar 2025, 19.30 Uhr; Campus-Aula PMS Kreuzlingen</p>
        <p><b>Konzert IV | Hochkarätige Kammermusik mit dem Trio Orelon</b></p>
        <p>Das Trio Orelon, Gewinner des ARD-Musikwettbewerbs 2023, begeistert mit Werken von Tschaikowsky, Ravel und Beethoven.</p>

        <p>
          <br/>
          <hr />
          <br/>
        </p>

        <p>Sonntag, 25. Mai 2025, 17 Uhr; Kirche St. Ulrich Kreuzlingen</p>
        <p><b>Konzert V | „Der versiegelte Engel“ mit dem Ensemble Cantissimo</b></p>
        <p>Rodion Shchedrins Chorkonzert, begleitet von Flötenimprovisationen von Matthias Ziegler, steht im Zentrum dieses Konzerts im Rahmen des 37. Bodenseefestivals.</p>

        <p>
          <br/>
          <hr />
          <br/>
        </p>

        <p>Sonntag, 29. Juni 2025, 19.30 Uhr; Atrium der Campus-Aula PMS Kreuzlingen</p>
        <p><b>Konzert VI | Sommerliches Serenadenkonzert mit dem Ensemble Sonus Brass</b></p>
        <p>Mit Werken von Rossini, Grieg, Kreisler, Bernstein und mehr begeistert das Ensemble Sonus Brass mit charmanter, humorvoller Moderation.</p>

        <p>
          <br/>
          <hr />
          <br/>
        </p>

        <p>Tickets: CHF 35 / € 35 | Schüler*innen und Studierende CHF 5 / €5</p>

        <p>ABONNEMENT 5 KONZERTE: CHF 140</p>
        <p>ÜBERTRAGBARE WERTKARTE KARTE: CHF 140 (berechtigt zum Eintritt von 5 Konzerten, gültig 2 Jahre)</p>
        <p>VORVERKAUF: <a css={css`text-decoration: none; color: ${color.secondaryDark};`} href="http://www.gml-kreuzlingen.ch/">www.gml-kreuzlingen.ch</a></p>
        <p>Abendkasse jeweils eine Stunde vor Konzertbeginn</p>

        <p>Die Konzerte der GML Kreuzlingen werden ermöglicht durch Stadt Kreuzlingen, Gemeinde Tägerwilen, Verein Kultursee, Kulturstiftung des Kantons Thurgau, Alice Wartemann – Stiftung und Migros Kulturprozent.<br/><br/></p>

        <Footer marginTop={false}/>
      </main>
    </Layout>
  )
}
